@mixin form() {
    /** Form styles **/

    form,
    fieldset {
        ul {
            list-style: none;

            li {
                &:before {
                    display: none;
                }
            }
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    textarea,
    select {
        box-sizing: border-box;
        border: 1px solid #747474;
        height: auto;
        margin: 0;
        outline: 0;
        padding: 12px;
        min-height: 43px;
        width: 100%;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
        margin-bottom: 28px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        margin: 0 4px 8px 0;
        margin-bottom: 28px;
    }

    input[type="datetime-local"],
    input[type="month"],
    input[type="week"] {
        padding: 7px 15px;
    }

    select {
        padding: 6px;
        border-radius: 2px;
    }

    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        @include button(sm, "full") {
            display: inline-block;
            @include sv(margin-top, 2);

            &:hover {
                border-color: transparent;
            }
        }
    }

    input[disabled],
    button[disabled] {
        background: #ccc;
        pointer-events: none;
    }

    input[type="reset"] {
        &:hover {
        }
    }

    fieldset {
        padding: 0;
        margin-bottom: 30px;
        border: none;
    }

    legend {
        @include sv(margin-bottom, 2.8);
    }

    label {
        @include fontstyle(text-base);
        display: inline-block;
        margin-bottom: 4px;
    }

    label.light {
        font-weight: 300;
        display: inline;
    }

    .number {
        background-color: #5fcf80;
        color: #fff;
        height: 30px;
        width: 30px;
        display: inline-block;
        font-size: 0.8em;
        margin-right: 4px;
        line-height: 30px;
        text-align: center;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
        border-radius: 100%;
    }

    @media screen and (min-width: 480px) {
        form {
            max-width: 480px;
        }
    }

    .js-form-type-select {
        position: relative;
        select {
            -moz-appearance: none;
            /* Firefox */
            -webkit-appearance: none;
            /* Safari and Chrome */
            appearance: none;
            padding-right: 10px;
        }

        // @include icon(right, $icon-chevron-down);

        &:after {
            padding-right: 10px;
            position: absolute;
            right: 0;
            pointer-events: none;
        }
    }

    .form-item {
        margin-top: 0;
        > label {
            margin-bottom: 0;
        }
        input[type="text"],
        input[type="password"],
        input[type="date"],
        input[type="datetime"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"],
        textarea,
        select,
        input[type="radio"],
        input[type="checkbox"] {
            margin-bottom: 0;
        }
    }

    @content;
}
