// print-dialog styles
.swal2-popup{
   padding-bottom: 0 !important;
   .swal2-icon.swal2-info.swal2-icon-show {
      display: none !important;
   }
   .swal2-icon.swal2-warning.swal2-icon-show{
      border: 0px;
      border-radius: none;
      justify-content: center;
      width: 60px;
      height: 60px;
      .swal2-icon-content{
         text-indent: -9999px;    
         background: url(../graphics/information-circle.svg) no-repeat;             /* sends the text off-screen */  /* shows image */
         white-space: nowrap;    
        background-size: 60px;
        width: 60px;
        height: 60px;
         }

   }
 
   .swal2-close{
      font-size: 2em;
      &:hover{
         color: $green !important;
      }
   }
   #swal2-title{
      @include sv-s("padding", 7.3, 7.5);
      color: #000;
      &.check-answer{
         @include sv("padding-top",1 );
         @include sv("padding-bottom",2 );
      }
   }
   #swal2-html-container{
      @include sv("margin-top",1 );
      @include sv("margin-bottom",4);
   }
   .swal2-actions{
      &.action-swap{
         @include flex-direction(row-reverse);
      }
      border-top: 1px solid #D0D2D9;
      width: 100%;
      @include sv('margin-top',0);
      .swal2-cancel.cancel{
         cursor: pointer;
         flex: 0.5;
         width: 100%;
         height: 100%;
         background-color: #FFF !important;
         border:none;
         @include sv("padding-top", 1.1);
         @include sv("padding-bottom", 1.4);
         display: flex !important;
         @include jc(center);
         @include ai(center);
         @include flex-direction(column);
         @include sv-s('margin', 0);
         color: #000;
         border-left: 1px solid #D0D2D9;
         border-radius: 0px;
         &:before{
            display: block;
            content: '';
            background: url(../graphics/pencil.svg) no-repeat;
            height: 24px;
            width: 24px;
            @include sv('margin-bottom', 1.2);
         }
      }
      .swal2-confirm{

         cursor: pointer;
         flex: 0.5;
         width: 100%;
         height: 100%;
         background-color: #FFF !important;
         border:none;
         @include sv("padding-top", 1.1);
         @include sv("padding-bottom", 1.4);
         display: flex !important;
         @include jc(center);
         @include ai(center);
         @include flex-direction(column);
         &.confirm-answer{
            margin:0;
            color: #000;
            background-color: #fff !important;
            border: 0px;
            border-left: 1px solid #D0D2D9;
         &:before{
            display: block;
            content: '';
            background: url(../graphics/reply.svg) no-repeat;
            height: 24px;
            width: 24px;
            @include sv('margin-bottom', 1.2);
         }
         }
    
      &.confirm-print{
         color: #000;
         &:before{
            display: block;
            content: '';
            background: url(../graphics/documents-file-text.svg) no-repeat;
            height: 24px;
            width: 24px;
            @include sv('margin-bottom', 1.2);
         }
      }
   }
      .swal2-deny.deny-print{
         color: #000;
         cursor: pointer;
         flex: 0.5;
         width: 100%;
         height: 100%;
         background-color: #FFF !important;
         border:none;
         border-left: 1px solid #D0D2D9;
         display: flex !important;
         @include jc(center);
         @include ai(center);
         @include flex-direction(column);
         @include sv("padding-bottom",1.4);
         @include sv("padding-top",1.1);
         @media screen and (max-width:#{$mobileBreakpoint - 1}){
            padding-top: 30px;
            @include sv-lr("padding",1);
         }
         &:before{
            display: block;
            content: '';
            background: url(../graphics/book.svg) no-repeat;
            height: 24px;
            width: 24px;
            @include sv('margin-bottom', 1.2);
         }
      }
   }
}

