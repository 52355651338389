// login-screen styles
// frontend-css styles
.logo-login {
 
   .logo {
      &.no-index{
         z-index: 0;
      }
      &.index{
         z-index: 1;
      }
      width: 200px;
      position: absolute;
      top: 4%;
      left: 8%;
      z-index: 1;
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
         left: 6%;
         top: 10%;
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         top: 6%;
         left: 14%;
      }
      @media screen and (min-width: 2300px) {
         left: 10%;
      }
      @media screen and (min-width: 3000px) {
         left: 12%;
      }
      @media screen and (max-width: 900px) and (min-width:767px) {
         left:14%;
      }
      p#logo a {
         display: block;
         width: 200px;
         background: url(../graphics/logo-green.svg) no-repeat;
         height: 40px;
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            margin-bottom: 50px;
         }
      }
   }
}

.login-full-wrapper {
   width: 200vw;
   height: 100vh;
   display: flex;
   
   //overflow: hidden;
   @include jc(center);
   @include ai(center);
   
   @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      min-height: 100%;
      height: auto;
      background-color: #f5f5f7;
   }
   @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
      // min-height: 100%;
      // height: auto;
   }
   &.animate {
     // position: relative;
      // animation: linear 0.5s alternate;
      // animation-name: run;
     transition: 0.5s ease-in;
      transform: translateX(-89vw);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         transform: translateX(-100vw);
      }
   }
   
   &.animate-back {
      position: relative;
      transition: 0.5s ease-out;
      transform: translateX(0vw);
     
   }

   .wrapper_login_welcome {
      width: 100vw;
      height: 100vh;
      @include flexbox;
      max-height: 1400px;
      flex-direction: row;
      background-color: white;
      
      @include sv-s("padding", 4.6, 9.2, 1.6, 9.2);

      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         height: auto;
         flex-direction: column;
         @include sv-s("padding", 2.6, 3.2, 2.4, 3.2);
      }
      @media screen  and (max-width: 820px) {
       //  height: auto;
         flex-direction: column;
         @include sv-s("padding", 2.6, 5.2, 2.4, 5.2);
      }
      .cell.login {
         flex: 0 0 40%;
         @include flexbox;
         @include flex-direction(column);
         // @include jc(center);

         .inner {
            @include flexbox;
            @include flex-direction(column);
            @include jc(space-between);
            max-width: 600px;
            height: 90%;
            max-height: 900px;
            margin: auto;
            @media screen and (max-width:#{$mobileBreakpoint - 1}){
               margin-top:40px;
            }
            @media screen and (max-width: 1600px) and (min-width:1500px){
               height: auto;
            }
            @media screen and (orientation: portrait) and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
               // margin-top:250px;
               height: 100%;
            }
         }
      }
      .masonry_gallery {
         flex: 0 0 60%;
         position: relative;
         .masonry_image {
            // position: absolute;
            object-fit: contain;
            border-radius: 20px;
            &#image-all {
               width: 100%;
               height: 100%;
               object-fit: contain;
               max-height: 1400px;
            }
         }
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            // display: none;
            @include sv("margin-top", 5);
            @include sv-tb("padding", 5);
         }
      }
      .inner {
         .welcome-text {
            color: rgba(0, 0, 0, 1);
            padding-top: 31px;
            font-size: 3.75rem;
            font-weight: 700;
            line-height: 3.75rem;
            @include sv("margin-top", 4);
         
            @media screen and (max-width: 900px) and (min-width:767px) {
               @include sv("margin-top", 25);
            }
            @media screen and (max-height: 700px) and (min-width:#{$mobileBreakpoint - 1}) {
              font-size: 2.5rem;
              line-height: 2.5rem;

            }
            @media screen and (max-width:#{$mobileBreakpoint - 1})  {
               font-size: 1.75rem;
               font-weight: 700;
               line-height: 1.75rem;
               @include sv("margin-top", 3);
            }
         }
         p.mission {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            margin-top: 50px;
            max-width: 424px;;
         }
         button.sign-in-to-continue {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: #0d8381;
            padding: 7px 20px;
            border: none;
            color: white;
            margin-bottom: 40px;
            font-weight: 700;
            line-height: 1.5rem;
            cursor: pointer;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               @include sv-tb("margin", 3);
            }
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
               @include sv-tb("margin", 5);
            }
            &::after {
               content: "";
               background: url(../../storage/arrow-circle-right.svg) no-repeat;
               display: inline-block;
               width: 30px;
               height: 30px;
               margin-left: 5px;
            }
         }
         p.create-account {
            max-width: 300px;
            font-size: 0.85rem;
            line-height: 1.25rem;
            font-weight: 400;
            a {
               color: rgba(0, 0, 0, 1);
            }
         }
      }
   }
   .login-form-input-wrapper {
      position: relative;
      .back-button {
         cursor: pointer;
         position: absolute;
         top:4%;
         left: 5%;
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            top:-46%;
            left: 5%;
         }
         @include icon(right, $icon-arrow-left);
         &:after {
            font-size: 1.5em;
         }
      }
      // position: absolute;
      width: 100vw;
      height: 100vh;
      background-color: #f5f5f7;
      display: flex;
      justify-content: center;
      align-items: center; 
      padding-right:10vw;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         min-height: 120%;
         padding-left:20px;
         padding-right:20px;
         height: auto;
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
         min-height: 100%;
         height: auto;
         // margin-top:120px;
      }
      &.visible {
         transform: translateX(-89vw);
         visibility: visible;
       
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            transform: translateX(-100vw);
         }
      }
      &.hidden {
         transform: translateX(0vw);
         visibility: hidden;
      }
      .inner {
        
         fieldset {
            @include sv-s("padding", 4, 8.8, 1.7, 8.8);
            background-color: white;
            border:none;
            box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            //box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
            
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               @include sv-s("padding", 4, 3, 1.7, 3);
            }
         }
         .error {
            color: red;
         }
         .logo-vertical {
            background: url(../graphics/logo-vertical.svg) center center no-repeat;
            height: 82px;
            @include sv("margin-bottom", 2);
            @include sv("margin-right", 1.2);
         }
         h2 {
            background: none !important;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
            padding-left: 0px !important;
            padding-right: 0px !important;
            color: #000000;
            @include sv("padding-bottom", 4);
         }
         #loginFormMain {
            input[type="password"],
            input[type="text"] {
               width: 100%;
               padding: 13px 0px 13px 20px;
               border: 1px solid $mono-grey;
               color: #454746;
               &::placeholder {
                  font-size: 0.85rem;
                  line-height: 1.25rem;
                  font-weight: 300;
                  // color: #454746;
               }
            }
            input[type="password"]{
               border-top:none;
               border-bottom-left-radius: 4px;
               border-bottom-right-radius: 4px;
               border-top-left-radius: 0px;
               border-top-right-radius: 0px;
            }
            input[type="text"]{
               border-top-left-radius: 4px;
               border-top-right-radius: 4px;
               border-bottom-right-radius: 0px;
               border-bottom-left-radius: 0px;
            }
            .checkbox-area {
               text-align: center;
               @include sv("margin-top", 2);
            }
         }
         .buttons {
            font-size: 0;
            text-align: center;
            @include sv-tb("margin", 2);
            //
            button[type="submit"] {
               cursor: pointer;
               border: 0px;
               &::after {
                  content: "TEST";
                  background: url(../graphics/arrow-circle-right-black.svg) no-repeat;
                  display: inline-block;
                  width: 40px;
                  height: 40px;
                  background-color: $white;
               }
            }
           
         }
         hr{
            border: none;
            height: 1px;
            background: #D0D2D9;
         }
         .extra_links {
            text-align: center;
            @include sv("margin-top", 1);
            a {
               color: #0070C9;
               @include sv("margin-bottom", 0.6);
               font-size: 0.85rem;
               line-height: 1rem;
               font-weight: 400;
               @include flexbox;
               @include ai(center);
               @include jc(center);
               &::after {
                  content: "";
                  background: url(../graphics/arrow-up-right.svg) no-repeat;
                  // transform: scale(1.4);
                  display: inline-block;
                  height: 13px;
                  width: 13px;
                  @include sv('margin-left', 0.4);
                  background-position: center 1px;
               }
            }
         }
      }
   }

}

@keyframes run {
   0% {
      right: 0%;
   }
   50% {
      right: 40%;
   }
   100% {
      right: 90%;
   }
}
@keyframes run-back {
   0% {
      right: 90%;
   }
   50% {
      right: 40%;
   }
   100% {
      right: 0%;
   }
}
