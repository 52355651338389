@mixin container($option: fluid, $max-width: 3840px) {
    width: calc(100vw - (128px * 2));
    margin: 0 auto;
    
	@include breakpoint(custom, 2560) {
		width: calc(100vw - (40px * 2));
	}
	
    .container {
		width: 100%;
	}
	
    @if $option == fluid   {
        max-width: 100%;
    } 

    @else if $option == xxl {
        max-width: 2600px;
    }
    @else if $option == xl {
        max-width: 2208px;
    }
    @else if $option == lg {
        max-width: 1800px;
    }
    @else if $option == md {
        max-width: 1720px;
        @include breakpoint(custom, 2560) {
            max-width: 900px;
        }
    }
    @else if $option == sm {
        max-width: 1280px;
    }
    @else if $option == xs {
        max-width: 1060px;
    }
    @else if $option == custom {
        max-width: $max-width;
    }
}