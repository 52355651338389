$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-arrow-left: "\e928";
$icon-x: "\e927";
$icon-menu: "\e92b";
$icon-user-circle-1: "\e92d";
$icon-arrow-circle-right: "\e92e";
$icon-arrow-circle-left: "\e92f";
$icon-arrow-circle-fill-black: "\e930";
$icon-chevron-right: "\e931";
$icon-arrow-circle-right-fill: "\e925";
$icon-check-circle: "\e926";
$icon-x-circle: "\e92c";
$icon-Assisting-electors: "\e900";
$icon-Ballot-box-guard: "\e901";
$icon-Ballot-Box: "\e902";
$icon-Ballot-paper: "\e903";
$icon-Before-polls-open_1: "\e904";
$icon-Before-polls-open: "\e905";
$icon-Candidate: "\e906";
$icon-Clean: "\e907";
$icon-Code-of-ethics: "\e908";
$icon-Counting: "\e909";
$icon-Declaration-table: "\e90a";
$icon-Do-not: "\e90b";
$icon-Do: "\e90c";
$icon-During-polling-day: "\e90d";
$icon-Early-voting-officerin-charge: "\e90e";
$icon-Early-voting-staff: "\e90f";
$icon-Elector-mark-off: "\e910";
$icon-Elector: "\e911";
$icon-End-of-polling-day: "\e912";
$icon-Enrolment-form: "\e913";
$icon-LAPPERDS: "\e914";
$icon-May: "\e915";
$icon-Mobile-Staff: "\e916";
$icon-Officer-in-Charge: "\e917";
$icon-Packaging: "\e918";
$icon-Polling-Area-Manager: "\e919";
$icon-Polling-Eve: "\e91a";
$icon-Polling-Officer: "\e91b";
$icon-Polling-period: "\e91c";
$icon-Polling-place: "\e91d";
$icon-Postal-votes: "\e91e";
$icon-Queue-Controller: "\e91f";
$icon-Scrutineers: "\e920";
$icon-Second-in-charge: "\e921";
$icon-Spoilt-Ballot-Paper: "\e922";
$icon-Time: "\e923";
$icon-Workplace-healthand-safety: "\e924";

