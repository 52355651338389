$black: #000;
$light-black: #333;
$medium-black: #2c2a29;
$mono-black: #454746;
$white: #fff;
$green: #003c34;
$light-yellow: #f7f5f1;
$yellow: #d6ce00;
$dark-grey: #e6e6e6;
$grey: #c4c4c4;
$light-grey: #f5f5f7;
$red: #cc0000;
$blue: #0065cc;
$mono-grey: #d0d2d9;
$light-green: #027a11;
$light-red: #ffd0d0;
$lighter-green: rgba(13, 131, 129, 0.3);
$form-matchup-blue: #d0ebff;
$form-matchup-green: #d0ffdd;
$form-matchup-mauve: #e2d0ff;
$form-matchup-khaki: #e7dcb8;
$form-matchup-yellow: #feffd0;

// $black-tint1: #F9F9F9;
// $black-tint2: #F5F5F5;

// $grey1: #EEE;
// $grey2: #DDD;
// $grey3: #888;

// $blue: #00283C;
// $blue-vivid: #00FFFA;

// $purple: #190F41;
// $purple-vivid: #652C92;

// $green: #052319;
// $green-vivid: #1EF56E;

// $red: #4B002D;
// $orange-vivid: #FF3250;
