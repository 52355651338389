.row{
	max-width: 1760px;
	width: calc(100% - #{$minmargin * 2}px);
	max-width: #{$site-width}px;
	margin: auto;
	.row{
		width: 100%;
	}
	&.collapse{
		max-width: 1080px;
	}
	&.extra{
		max-width: 688px;
	}
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
		width: calc(100% - #{$sv-r * 2});
		&.collapse{
			width: calc(100% - #{$sv-r * 8});
		}
	}
}

.rowbreaker{
	@include rowbreaker();
}

.container {
	&-fluid {
		@include container(fluid);
	}
	&-xxl {@include container(xxl);}
	&-xl {@include container(xl);}
	&-lg {@include container(lg);}
	&-md {@include container(md);}
	&-sm {@include container(sm);}
	&-xs {@include container(xs);}
}

