.text {
    // Font sizes
    &-9xl {
        @include fontstyle(text-9xl);


        *,
        a {
            @include fontstyle(text-9xl);

        }
    }

    &-8xl {
        @include fontstyle(text-8xl);


        *,
        a {
            @include fontstyle(text-8xl);

        }
    }

    &-7xl {
        @include fontstyle(text-7xl);


        *,
        a {
            @include fontstyle(text-7xl);

        }
    }

    &-6xl {
        @include fontstyle(text-6xl);


        *,
        a {
            @include fontstyle(text-6xl);

        }
    }

    &-5xl {
        @include fontstyle(text-5xl);

        *,
        a {
            @include fontstyle(text-5xl);
        }
    }

    &-4xl {
        @include fontstyle(text-4xl);

        *,
        a {
            @include fontstyle(text-4xl);
        }
    }

    &-3xl {
        @include fontstyle(text-3xl);

        *,
        a {
            @include fontstyle(text-3xl);
        }
    }

    &-2xl {
        @include fontstyle(text-2xl);

        *,
        a {
            @include fontstyle(text-2xl);
        }
    }

    &-xl {
        @include fontstyle(text-xl);

        *,
        a {
            @include fontstyle(text-xl);
        }
    }

    &-lg {
        @include fontstyle(text-lg);


        *,
        a {
            @include fontstyle(text-lg);
        }
    }

    &-base {
        @include fontstyle(text-base);


        *,
        a {
            @include fontstyle(text-base);
        }
    }

    &-sm {
        @include fontstyle(text-sm);


        *,
        a {
            @include fontstyle(text-sm);
        }
    }

    &-xs {
        @include fontstyle(text-xs);


        *,
        a {
            @include fontstyle(text-xs);
        }
    }

    // Font weights
    &-bold {
      font-weight: 700;
    }
    &-semi-bold {
        font-weight: 500;
    }
    &-regular {
        font-weight: 400;
    }
}
