// rich-text styles
.workbookContent {
   .inner .inner2 {
      .UIHTMLContent {
         ul,
         ol {
            @include sv("margin-left", 1.5);
            @include sv("margin-bottom", 1.2);
         }
      }
   }
}
