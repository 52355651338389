// result-page styles
.resultsPage {
   //needs more stylings
   // margin: 0 auto 0 5%;
   background-color: #fff;
   .resultFull {
      background-color: $light-yellow;

      .resultFullInner {
         @include flexbox;
         flex-wrap: nowrap;
         max-width: 1440px;
         margin: 0 auto;
 
         @include sv-s("padding", 8, 7.2, 8, 15);
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv-s("padding", 2, 4, 6, 4);
            flex-wrap: wrap;
         }
         @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
             flex-wrap: wrap;
            @include sv-s("padding", 4, 4, 4, 4);
            margin: 0 auto 0 5%;
         }
         .finalScoreLarge {
            flex: 0 0 50%;
            h1 {
               font-size: 8rem;
               line-height: 8rem;
               font-weight: 700;
               margin-top: 40px;
               margin-bottom: 110px;
            }
            h2 {
               display: block;
               color: $green;
               font-size: 2.25rem;
               line-height: 2.5rem;
               font-weight: 400;
            }
            h2.marker {
               font-size: 0;
               &:after {
                  font-size: 1rem;
                  content: "_________";
               }
            }
         }
         .thank-you-note {
            @include flexbox;
            @include flex-direction(column);
            @include jc(flex-end);
            p {
               line-height: 1.5rem;
            }
            .buttons {
               @include sv-s("padding", 5, 0, 4, 0);
               a {
                  @include sv-s("padding", 1, 2.4);
                  background-color: $green;
                  color: $white;
                  text-decoration: none;
                  border-radius: 8px;
               }
            }
         }
      }
   }
   .buttons {
      background-color: $light-yellow;
      @include sv("padding-left", 10);
   }
   .breakdown {
      @include sv-s("margin", 4, 0, 6, 0);
      max-width: 1440px;
      margin: 0 auto;
      table.breakdownTable {
         width: 70%;
         text-align: left;
         border-collapse: collapse;
         @include sv("margin-left", 16);
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv("margin-left", 4);
         }
         thead {
            border-bottom: solid white 30px;
            tr {
               position: relative;
               h2 {
                  font-size: 0;
                  &:after {
                     font-size: 1rem;
                     content: "_________";
                  }
               }
               th {
                  @include sv("margin-bottom", 1);

                  &.title {
                     display: block;
                     color: $green;
                     font-size: 2.25rem;
                     line-height: 2.5rem;
                     font-weight: 400;
                  }
                  &.score {
                     position: absolute;
                     bottom: 10px;
                     right: 0px;
                  }
               }
            }
         }
         tbody {
            border-bottom: 80px solid transparent;
            tr {
               border-bottom: 20px solid transparent;
               td.title {
                  width: 60%;
                  line-height: 1.5rem;
                  font-weight: 400;
               }
               .score {
                  text-align: right;
               }
            }
         }
      }
   }
   .finalScore {
      @include sv-s("padding", 8, 7.2, 8, 15);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         @include sv-s("padding", 2, 4, 6, 4);
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
         @include sv-s("padding", 4, 4, 4, 4);
         margin: 0 auto 0 5%;
      }
      border-top: 1px solid $light-grey;
      width: 90%;
      // max-width: 1440px;
      // margin: 0 auto;
      @include sv-s("padding", 8, 7.2, 8, 15);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         @include sv-s("padding", 2, 4, 6, 4);
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
         @include sv-s("padding", 4, 4, 4, 4);
         margin: 0 auto 0 5%;
      }
      .finalScoreInner {
         @include flexbox;
         @include jc(space-between);
         font-size: 1.5rem;
         line-height: 2rem;
         font-weight: 400;
         color: $green;
         .clear {
            display: none;
         }
      }
   }
}
