@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xir4j9');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xir4j9#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xir4j9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xir4j9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xir4j9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-x {
  &:before {
    content: $icon-x; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-user-circle-1 {
  &:before {
    content: $icon-user-circle-1; 
  }
}
.icon-arrow-circle-right {
  &:before {
    content: $icon-arrow-circle-right; 
  }
}
.icon-arrow-circle-left {
  &:before {
    content: $icon-arrow-circle-left; 
  }
}
.icon-arrow-circle-fill-black {
  &:before {
    content: $icon-arrow-circle-fill-black; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-arrow-circle-right-fill {
  &:before {
    content: $icon-arrow-circle-right-fill;     
    color: #fff;
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle;     
    color: #0d8381;
  }
}
.icon-x-circle {
  &:before {
    content: $icon-x-circle;     
    color: #df0e14;
  }
}
.icon-Assisting-electors {
  &:before {
    content: $icon-Assisting-electors; 
  }
}
.icon-Ballot-box-guard {
  &:before {
    content: $icon-Ballot-box-guard; 
  }
}
.icon-Ballot-Box {
  &:before {
    content: $icon-Ballot-Box; 
  }
}
.icon-Ballot-paper {
  &:before {
    content: $icon-Ballot-paper; 
  }
}
.icon-Before-polls-open_1 {
  &:before {
    content: $icon-Before-polls-open_1; 
  }
}
.icon-Before-polls-open {
  &:before {
    content: $icon-Before-polls-open; 
  }
}
.icon-Candidate {
  &:before {
    content: $icon-Candidate; 
  }
}
.icon-Clean {
  &:before {
    content: $icon-Clean; 
  }
}
.icon-Code-of-ethics {
  &:before {
    content: $icon-Code-of-ethics; 
  }
}
.icon-Counting {
  &:before {
    content: $icon-Counting; 
  }
}
.icon-Declaration-table {
  &:before {
    content: $icon-Declaration-table; 
  }
}
.icon-Do-not {
  &:before {
    content: $icon-Do-not; 
  }
}
.icon-Do {
  &:before {
    content: $icon-Do; 
  }
}
.icon-During-polling-day {
  &:before {
    content: $icon-During-polling-day; 
  }
}
.icon-Early-voting-officerin-charge {
  &:before {
    content: $icon-Early-voting-officerin-charge; 
  }
}
.icon-Early-voting-staff {
  &:before {
    content: $icon-Early-voting-staff; 
  }
}
.icon-Elector-mark-off {
  &:before {
    content: $icon-Elector-mark-off; 
  }
}
.icon-Elector {
  &:before {
    content: $icon-Elector; 
  }
}
.icon-End-of-polling-day {
  &:before {
    content: $icon-End-of-polling-day; 
  }
}
.icon-Enrolment-form {
  &:before {
    content: $icon-Enrolment-form; 
  }
}
.icon-LAPPERDS {
  &:before {
    content: $icon-LAPPERDS; 
  }
}
.icon-May {
  &:before {
    content: $icon-May; 
  }
}
.icon-Mobile-Staff {
  &:before {
    content: $icon-Mobile-Staff; 
  }
}
.icon-Officer-in-Charge {
  &:before {
    content: $icon-Officer-in-Charge; 
  }
}
.icon-Packaging {
  &:before {
    content: $icon-Packaging; 
  }
}
.icon-Polling-Area-Manager {
  &:before {
    content: $icon-Polling-Area-Manager; 
  }
}
.icon-Polling-Eve {
  &:before {
    content: $icon-Polling-Eve; 
  }
}
.icon-Polling-Officer {
  &:before {
    content: $icon-Polling-Officer; 
  }
}
.icon-Polling-period {
  &:before {
    content: $icon-Polling-period; 
  }
}
.icon-Polling-place {
  &:before {
    content: $icon-Polling-place; 
  }
}
.icon-Postal-votes {
  &:before {
    content: $icon-Postal-votes; 
  }
}
.icon-Queue-Controller {
  &:before {
    content: $icon-Queue-Controller; 
  }
}
.icon-Scrutineers {
  &:before {
    content: $icon-Scrutineers; 
  }
}
.icon-Second-in-charge {
  &:before {
    content: $icon-Second-in-charge; 
  }
}
.icon-Spoilt-Ballot-Paper {
  &:before {
    content: $icon-Spoilt-Ballot-Paper; 
  }
}
.icon-Time {
  &:before {
    content: $icon-Time; 
  }
}
.icon-Workplace-healthand-safety {
  &:before {
    content: $icon-Workplace-healthand-safety; 
  }
}

