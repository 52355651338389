// sidebar styles
.sidebar-content {
   // height: 100vh;
   // overflow-x: hidden;
   @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
      width: 33%;
   }
   box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
   @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      display: none;
      width: 100vw;
      height: 100vh;
   }
   #main {
      .panel-inner #nav {
         height: 100%;

         .inner1 {
            position: relative;
            .logo-wrapper {
               @include flexbox;
               @include jc(space-between);
               .logo {
                  background: url(../graphics/election-logo-page.png) no-repeat;
                  width: 155px;
                  height: 35px;
                  @include sv-s("margin", 2, 0, 0, 2);
               }
               .cross {
                  display: none;
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                     display: block;
                     @include icon(left, $icon-x);
                     font-weight: 600;
                     font-size: 2rem;
                     @include sv-s("margin", 2, 3, 0, 2);
                  }
               }
            }
            .inner2 {
               @include sv-s("margin", 4, 0, 10, 0);
               min-height: 65vh;
               .training-navigation {
                  @media screen and (min-width:#{$mobileBreakpoint - 1}){
                     height: calc(100vh - 400px);
                     overflow-y: scroll;    
                  }
           
                  ul.parts {
                     > li {
                        @include sv-s("padding", 0, 7.2, 0, 2);
                        list-style: none;
                        font-size: 1.124rem;
                        line-height: 1.75rem;
                        font-weight: 700;
                        @include sv-tb("margin", 2);
                        a {
                           text-decoration: none;
                           color: $black;
                        }
                        > ul {
                           display: none;
                        }
                        &.active {
                           @include sv-s("padding", 2);
                           background-color: $light-yellow;
                           > ul {
                              display: block;

                              @include sv-s("padding", 2, 7.2, 0, 2);
                              > li {
                                 @include sv("margin-bottom", 1);
                                 list-style: none;
                                 font-size: 1rem;
                                 line-height: 1.5rem;
                                 font-weight: 400;
                                 &.active {
                                    font-size: 1rem;
                                    line-height: 1.75rem;
                                    font-weight: 700;
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
            .user-information {
               @media screen and (min-width:#{$mobileBreakpoint - 1}){
                  position: fixed;
                  bottom:40px;
               }
               @media screen and (max-width:#{$mobileBreakpoint - 1}){
                  @include sv("padding-bottom", 15);
                  @include jc(space-between);
               }
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                  @include jc(space-between);
               }
               @include sv-lr("padding", 2);
               @include flexbox;
               
               .user {
                  @include sv('margin-right', 4);
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                     @include sv('margin-right', 3);
                  }
                  &::before {
                     content: "";
                     display: inline-block;
                     height: 44px;
                     width: 44px;
                     background: url(../graphics/icon-user.gif) no-repeat;
                     background-size: cover;
                     border-radius: 50px;
                  }
                  > span {
                     display: block;
                     font-size: 0.875rem;
                     line-height: 1.5rem;
                     &.name,
                     &.role {
                        font-weight: 700;
                     }
                  }
               }
               .logout {
                  align-self: flex-end;
                  @include sv("margin-bottom", 1);
                  a {
                     &:hover{
                        background-color: $green;
                        color: $white;
                     }
                     color: $green;
                     text-decoration: none;
                     @include sv-s("padding", 1, 4);
                     border: 1px solid $green;
                     border-radius: 8px;
                     transition: 0.2s ease-in;
                  }
               }
            }
         }
      }
   }
}
