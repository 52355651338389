// password-reset-form styles
.reset-instruction {
   width: 100vw;
   @include sv("margin-top", 10);
   @include flexbox;
   @include jc(center);
   .container {
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         @include sv-s("padding", 4, 4, 1.7, 4);
      }
      box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      @include sv-s("padding", 4, 8.8, 1.7, 8.8);
      text-align: center;
      .logo-vertical {
         background: url(../graphics/logo-vertical.svg) center center no-repeat;
         height: 82px;
         @include sv("margin-bottom", 2);
      }
      .inner {
         h2 {
            @include sv("margin-bottom", 2);
         }
      }
   }
}
.password-reset-wrapper {
   width: 100vw;
   @include sv("margin-top", 10);
   @include flexbox;
   @include jc(center);
   .container {
      // max-width: 1400px;
      box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      @include sv-s("padding", 4, 8.8, 1.7, 8.8);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         @include sv-s("padding", 4, 4, 1.7, 4);
      }
      text-align: center;
      .logo-vertical {
         background: url(../graphics/logo-vertical.svg) center center no-repeat;
         height: 82px;
         @include sv("margin-bottom", 2);
      }
      .cell.green {
         max-width: 600px;
         margin-bottom: 20px;
         .inner {
            h2 {
               background: none !important;
               font-weight: 700;
               font-size: 1.5rem;
               line-height: 2rem;
               text-align: center;
               padding-left: 0px !important;
               padding-right: 0px !important;
               color: #000000;
               @include sv("padding-bottom", 2);
            }
         }
      }
      .cell.white {
         .inner {
            h2 {
               background: none !important;
               font-weight: 700;
               font-size: 1.5rem;
               line-height: 2rem;
               text-align: center;
               padding-left: 0px !important;
               padding-right: 0px !important;
               color: #000000;
               @include sv("padding-bottom", 2);
            }
            .login-form {
               .field {
                  .field-inner {
                     @include flexbox;
                     @include flex-direction(column);
                     @include sv("margin-top", 1);
                     input[type="password"] {
                        @include sv("margin-top", 1);
                        width: 100%;
                        padding: 13px 0px 13px 20px;
                        border: 1px solid $mono-grey;
                        color: #454746;
                        &::placeholder {
                           font-size: 0.85rem;
                           line-height: 1.25rem;
                           font-weight: 300;
                           // color: #454746;
                        }
                     }
                  }
               }
               .buttons {
                  @include sv("margin-top", 3);
                  input {
                     border-radius: 10px;
                     background-color: #0d8381;
                     padding: 7px 20px;
                     border: none;
                     color: white;
                     margin-bottom: 40px;
                     font-weight: 700;
                     line-height: 1.5rem;
                     @include sv("margin-right", 2);
                  }
               }
            }
         }
      }
   }
}
