// Website container
.layout-container {
	min-height: 100vh;
    margin: 0 auto!important;
	.right-section {
		@include flex(1);
	}

    .content-wrapper {
        @include breakpoint-min(custom, 1280) {
            @include row_nw;
        }
        .content-main {
            @include flex(1);
        }
    }
}


