/* reset input areas to default styles */
@mixin appearance-none(){
	-ms-appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent;
	border-radius: 0;
	border: 0;
	font-family: inherit;
	font-display: block;
	font-weight: inherit;
	font-size: inherit;
	&::-ms-expand {
	    display: none;
	}
}

/* accessible version of display: none; */
@mixin display-none(){
	display: block;
	height: 0;
	width: 0;
	overflow: hidden;
	margin: 0;
	border: 0;
	-ms-appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}


// Other mixins

/* TEXT SHADOW */
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4)) {
	text-shadow: $x $y $blur $color;
}

/* BOX SHADOW */
@mixin box-shadow() {
	box-shadow: 1px 2px 3px rgba(#222222, 0.3), 6px 6px 8px rgba(#222222, 0.1);
}

/* TRANSFORM  */
@mixin transform($params) {
	-webkit-transform: $params;
	-moz-transform: $params;
	-ms-transform: $params;
	transform: $params;
}

/* TRANSFORM ORIGIN */
@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-o-transform-origin: $origin;
	transform-origin: $origin;
}

/* TRANSITION */
// @mixin transition($what: all, $length: 1s, $easing: ease-in-out) {
//     -moz-transition:    $what $length $easing;
//     -o-transition:      $what $length $easing;
//     -webkit-transition: $what $length $easing;
//     -ms-transition:     $what $length $easing;
//     transition:         $what $length $easing;
// }

/* calculate ems */
@function em($pixels, $context: $browser-context) {
	@return #{$pixels/$context}em;
}

/* calculate rems */
@function rem($pixels, $context: $browser-context) {
	@return #{$pixels/$context}rem;
}



/* spacing variable mixin - used for padding and margins, the second variable is a multiplier */
@mixin sv($prop, $x: 1) {
	#{$prop}: #{$sv * $x};

	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
		#{$prop}: #{$sv-r * $x};
	}
}


@mixin sv-tb($prop, $x: 1) {
	#{$prop}-top: #{$sv * $x};
	#{$prop}-bottom: #{$sv * $x};

	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
		#{$prop}-top: #{$sv-r * $x};
		#{$prop}-bottom: #{$sv-r * $x};
	}
}

@mixin sv-lr($prop, $x: 1) {
	#{$prop}-left: #{$sv * $x};
	#{$prop}-right: #{$sv * $x};

	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
		#{$prop}-left: #{$sv-r * $x};
		#{$prop}-right: #{$sv-r * $x};
	}
}

@mixin sv-s($prop, $tb: 1, $lr: $tb, $b: $tb, $l: $lr) {
	#{$prop}: #{$sv * $tb} #{$sv * $lr} #{$sv * $b} #{$sv * $l};

	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
		#{$prop}: #{$sv-r * $tb} #{$sv-r * $lr} #{$sv-r * $b} #{$sv-r * $l};
	}
}

/* calculate the view width based on a fixed value
 $prop : the property to set (if its hieght or width, max- will be set too
 $w : the width the calculate
 $mw : the max with, ie the value to calculate the variable related to, defaults to the $site-width
*/

@mixin vw($prop, $w, $mw: 0, $important: null) {
	$swppmm: ($site-width + ($minmargin * 2)
);

@if ($mw  !=0) {
	$swppmm: $mw;
}

#{$prop}: #{($w / $swppmm) * 100}vw $important;

@if (str-index($prop, 'width')) or (str-index($prop, 'height')) {
	max-#{$prop}: #{$w}px $important;
}
}

/* set a property to the value page margin - useful for blocks that arent rows but should sit inside it */
@mixin page-margin($prop) {
	#{$prop}: $page-min-margin;

	@media screen and (min-width: #{$site-width + ($minmargin * 2)}px) {
		#{$prop}: $page-margin;
	}
}

@mixin container-margin($prop, $width, $neg: 0) {
	
    @if $neg == 0 {
		#{$prop}: calc((100vw + #{$width}px) / 2);
	} @else {
        #{$prop}: calc((-100vw + #{$width}px) / 2);
    }
	// @media screen and (min-width: #{$site-width + ($minmargin * 2)}px) {
	// 	#{$prop}: $page-margin;
	// }
}

$page-margin-neg: calc((-100vw + #{$site-width}px) / 2);


@function sv($m) {
	@return #{$sv*$m};
}

@function svp($m) {
	@return #{$sv-r*$m};
}

@mixin row {
	width: calc(100% - #{$minmargin * 2}px);
	max-width: #{$site-width}px;
	margin: auto;

	.row {
		width: 100%;
	}

	&.collapse {
		max-width: 1080px;
	}

	&.extra {
		max-width: 688px;
	}

	// @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
	// 	width: calc(100% - #{$sv-r * 2});

	// 	&.collapse {
	// 		width: calc(100% - #{$sv-r * 8});
	// 	}
	// }
}

@mixin rowbreaker {
	width: 100vw;
	margin-left: #{$sv-r * -1};

	@media screen and (min-width: #{$site-width + ($minmargin * 2)}px) {
		margin-left: $page-margin-neg;
	}

	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) {
		// and (min-width:$mobileBreakpoint) {
		padding-left: $page-min-margin;
		padding-right: $page-min-margin;
	}

	@media screen and (max-width:#{$mobileBreakpoint - 1}) {
		margin-left: #{$page-min-margin * -1};
	}
}

@mixin collapse {
	max-width: 1080px;
}

@mixin extra {
	max-width: 688px;
    margin: 0 auto;
}

@mixin extrabreaker {
    width: 100vw;
	// margin-left: #{$sv-r * -1};

	@media screen and (min-width: #{$site-width + ($minmargin * 2)}px) {
		// margin-left: $page-margin-neg;
        @include container-margin(margin-left, 688, 1);
	}

	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) {
		// and (min-width:$mobileBreakpoint)
		padding-left: $page-min-margin;
		padding-right: $page-min-margin;
	}

	// @media screen and (max-width:#{$mobileBreakpoint - 1}) {
	// 	margin-left: #{$page-min-margin * -1};
	// }
}


@mixin block_margin() {
	@include sv-tb(margin, 12.8);

	@media screen and (max-width:#{$mobileBreakpoint - 1}){
		@include sv-tb(margin, 4.8);
	}
}

@mixin block_padding() {
	@include sv-tb(padding, 12.8);

	@media screen and (max-width:#{$mobileBreakpoint - 1}){
		@include sv-tb(padding, 4.8);
	}
}