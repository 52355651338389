// feedback styles
.feedback-wrapper {
   max-width: 1440px;
   margin: 0 auto;
   padding: 0 72px 80px 150px;
   @include flexbox;
   @include jc(space-between);
   // @include ai(center);
   @include sv("margin-top", 9);
   @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
      margin: 40px auto 0 5%;
      @include sv-s("padding", 2, 4, 5, 4);
   }
   @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      padding: 0;
      @include flex-direction(column);
      // @include ai(center);
      @include sv-lr("margin", 2);
   }
   .feedback-heading {
      h2 {
         font-size: 0;
         &:after {
            font-size: 1rem;
            content: "_________";
         }
      }
      h3 {
         font-size: 2.25rem;
         line-height: 2.5rem;
         color: $green;
         max-width: 512px;
      }
   }
   .feedback-content {
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
         @include sv('margin-left', 4);
      }
      max-width: 510px;
      p {
         @include sv("margin-top", 2);
      }
   }
}
.blockOfforms {
   .CMSFormOuter {
      @include sv("margin-top", 10);
      .CMSForm {
         max-width: 704px;
         margin: 0 auto;
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv-lr("margin", 2);
         }
         @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
            @include sv-lr("padding", 6);
         }
         .formFields {
            .fieldItem {
               border-bottom: 0px;
               .fieldItemLabel label {
                  line-height: 1.5rem;
                  font-weight: 700;
               }
               .fieldItemInput {
                  input {
                     min-height: 44px;
                     width: 96%;
                     border: 1px solid $mono-grey;
                     border-radius: 8px;
                  }
                  textarea {
                     border: 1px solid $mono-grey;
                     border-radius: 8px;
                  }
               }
            }
         }
         .formButtons {
            text-align: center;
            input {
               color: $green;
               border: 1px solid $green;
               background-color: $white;
               @include sv-s("padding", 1, 4);
               border-radius: 8px;
               &:hover{
                  background-color: $green;
                  color: $white;
                  transition: 0.2s ease-in;
                  cursor: pointer;
               }
            }
            
         }
      }
   }
}
