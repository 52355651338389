@mixin fontstyle($style, $color: $black, $font_weight: "") {
  @if $color != default {
    color: $color;
  }


  @if $style == text-xs {
    @include font(12);
    font-weight: 400;
    line-height: rem(16);
  }
  @if $style == text-sm {
    @include font(14);
    font-weight: 400;
    line-height: rem(20);
  }
  @if $style == text-base {
    @include font(16);
    font-weight: 400;
    line-height: rem(24);
  }
  @if $style == text-lg {
    @include font(18);
    font-weight: 400;
    line-height: rem(24);
  }
  @if $style == text-xl {
    @include font(20);
    font-weight: 400;
    line-height: rem(28);
  }
  @if $style == text-2xl {
    @include font(24);
    font-weight: 400;
    line-height: rem(30);
  }
  @if $style == text-3xl {
    @include font(30);
    font-weight: 400;
    line-height: rem(36);
  }
  @if $style == text-4xl {
    @include font(36);
    font-weight: 400;
    line-height: rem(38);
    // test
  }
  @if $style == text-5xl {
    @include font(48);
    font-weight: 400;
    line-height: rem(54);
  }
  @if $style == text-6xl {
    @include font(60);
    font-weight: 400;
    line-height: rem(68);
  }
  @if $style == text-7xl {
    @include font(72);
    font-weight: 400;
    line-height: rem(72);
  }
  @if $style == text-8xl {
    @include font(96);
    font-weight: 400;
    line-height: rem(96);
  }
  @if $style == text-9xl {
    @include font(128);
    font-weight: 400;
    line-height: rem(128);
  }
  @if $style == page-title {
    @include fontstyle(text-5xl);
    font-weight: 700;
  }
  @if $style == sub-title {
    @include fontstyle(text-4xl);
    font-weight: 700;
  }
  @if $style == feature {
    @include fontstyle(text-2xl);
    font-weight: 500;
  }
  @if $style == tag {
    @include fontstyle(text-xs);
  }
  @if $style == body {
    @include fontstyle(text-base, $black)
  }
  @else {

  }

  @if $font_weight != "" {
    font-weight: $font_weight;
  }
}