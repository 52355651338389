// quick-tour-custom styles
.wt-popover.centre-page,
.wt-popover.wt-slides {
   .wt-popover-inner {
      padding: 0px;
      .wt-content {
         .wt-btn-back.close{
            float:right;
            color: black;
            border:none;
            &:hover{
               color: $white;
               background-color: $green;
            }
         }
         .dynamic-textbox .buttons {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            > button {
               display: block;
               float: none;
               line-height: 1.5rem;
               &.wt-btn-next {
                  @include flexbox;
                  @include ai(center);
                  @include jc(center);
                  
                  span {
                     &:before {
                        font-size: 1.75rem;
                        @include sv("margin-left", 0.4);
                     }
                  }
               }
               &.wt-btn-back {
                  font-weight: 400;
                  color: $green;
                  background: none;
                  border: none;
                  text-decoration: underline;
               }
            }
         }
      }
   }
   .wt-arrow {
      display: none;
   }
   .button-group {
      @include flexbox;
      @include jc(space-between);
      @include flex-direction(row-reverse);
      border-top: 1px solid $mono-grey;
      .button-next,
      .button-prev {
         min-height: 80px;
         flex: 50%;
         @include flexbox;
         @include jc(center);
         @include ai(center);
         button {
            border: none;
            background-color: white !important;
            color: $mono-black !important;
            font-size: 1rem;
            font-weight: 400;
            @include flexbox;
            @include ai(center);
            @include jc(center);
            width: 100%;
            height: 100%;
            span {
               @include sv-lr("margin", 1.6);
               &::before {
                  font-size: 1.5rem;
               }
            }
         }
      }
      .button-next {
         border-left: 1px solid $mono-grey;
      }
   }
   .wt-btns.wt-btn-back {
      &:empty {
         display: none;
      }
      float: left;
   }
   .wt-content {
      .slider-position {
         @include flexbox;
         @include jc(center);
         .circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 1px solid $mono-black;
            @include sv("margin-right", 1);
            &.active {
               background-color: $mono-black;
            }
         }
      }
   }
}
