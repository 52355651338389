/* Settings */

$site-width: 1600;
/* (max site width) */

$browser-context: 20;
/* (base font size) */
$line-height: 28;

$defaultFont: "Helvetica", sans-serif;
/* (default font) */
$defaultFontWeight: 400;
/* (default font wieght) */

$sv: 10px;
/* (spacing variable) */

$minmargin: 36;
/* (minimum page magin - left and right gap on mobile) */
$page-min-margin: 36px;
/* (same as above, but with unit) */

$mobileBreakpoint: 767px;
/* (where the site goes mobile) */

/* calculate page margin */
$page-margin: 36px;
$page-margin-neg: -36px;

/* responsive version of $sv (spacing variable) set above */
$sv-r: ($sv / ($site-width + ($page-min-margin * 2))) * 100vw;

$image_folder: "../images" !default;
$font_folder: "../typography" !default;
$font_size: 16px;

$box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
