// training-package styles
.container-max-width {
   max-width: 1440px;
   margin: 0 auto;
}
.withNav {
   #content {
      // height: calc(100vh - 160px);
      // max-width: 1440px;
      // margin: 0 auto;
      height: max-content;
      // @include sv("padding-bottom", 5);
      .UIBlockContainer {
         .blockOftraining {
            background-color: #F7F5F1;
            .workbookContent {
               // background-color: $light-yellow;
               @include sv-s("padding", 8, 7.2, 8, 15);
              
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  @include sv-s("padding", 2, 4, 6, 4);
               }
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                  @include sv-s("padding", 4, 4, 4, 4);
                  margin: 0 auto 0 5%;
               }
               // @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
               //    @include sv-s("padding", 2, 2, 6, 1);
               // }
               .inner {
                  .heading {
                     @include sv("padding-bottom", 4);
                     h2 {
                        font-size: 0;
                        &:after {
                           font-size: 1rem;
                           content: "_________";
                        }
                     }
                     h3 {
                        font-size: 2.25rem;
                        line-height: 2.5rem;
                        color: $green;
                        max-width: 512px;
                     }
                  }
                  .inner2 {
                     .UIHTMLContent {
                        .content-first-page{
                           margin-left: -560px;
                           @media screen and (max-width:#{$mobileBreakpoint - 1}){
                              margin-left: 0px;
                           }
                           @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                              margin-left: 0px; 
                           }
                        }
                        > h4{
                           display: none;
                        }
                        position:relative;
                        padding: 0px 0px 0px 560px;
                        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                          padding: 0px 0px 0px 0px;
                        }
                        @media screen and (max-width:#{$mobileBreakpoint - 1}){
                           padding: 0px 0px 0px 0px;
                        }
                        .text-placeholder{
                           @include sv("margin-bottom", 10);
                           >div h4{
                              display: none;
                           }
                           @media screen and (max-width:#{$mobileBreakpoint - 1}){
                              min-height: fit-content !important;
                              @include sv("margin-bottom", 4); 
                           }
                           @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                              @include sv("margin-bottom", 4);
                           }
                        }
                        .media-placeholder{
                           h4{
                              max-width: 400px;
                              @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                                 max-width: 100%;
                                 @include sv-tb('margin', 2);
                              }
                              @media screen and (max-width:#{$mobileBreakpoint - 1}){
                                 max-width: 100%;
                                 @include sv-tb('margin', 2);
                              }
                           }
                           @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                             position: relative;
                           }
                           @media screen and (max-width:#{$mobileBreakpoint - 1}){
                              position: relative;
                           }
                           position: absolute;
                           left: 0;
                           img[style="highlight-media"]{
                              @include sv("margin-top", 4);
                              width: 400px;
                              height: 400px;
                              object-fit: cover;
                              border-radius: 20px;
                              @media screen and (max-width:#{$mobileBreakpoint - 1}){
                                 width: 100%;
                                 height: 100%;
                                 max-height: 250px;
                              }
                           }
                        }
                        iframe {
                           width: 100%;
                           @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                              width: 100%;
                           }
                        }
                        p {
                           line-height: 1.5rem;
                           @include sv("margin-bottom", 2);
                           img{
                              @media screen and (max-width:#{$mobileBreakpoint - 1}){
                                 max-width: 100%;
                              max-height: 100%;
                              }
                              @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                                 max-width: 100%;
                                 max-height: 100%;
                              }
                              
                           }
                        }
                        h4{
                           font-size: 1.5em;
                           color: $mono-black;
                           line-height: 1.2em;
                           font-weight: 700;
                        }
                        .masonry_gallery {
                           text-align: center;
                         img{
                           width: 750px;
                           height: 650px;
                         }
                           @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                              img{
                                 width: 50%;
                                 height: 50%;
                              }
                           }
                           @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                              width: 100%;
                              height: 100%;
                           }
                           
                           
                           @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                              // display: none;
                            
                              img{
                                 width: 100%;
                                 height: 100%;
                              }
                           }
                        }
                        .align-center {
                           text-align: center;

                           h3 {
                              font-size: 3.75rem;
                              font-weight: 700;
                              line-height: 3.75rem;
                              max-width: 832px;
                              margin: 0 auto;
                              @include sv("margin-bottom", 4);
                              @include sv("margin-top", 6);
                              @media screen and (max-width:#{$mobileBreakpoint - 1}){
                                 @include sv("margin-top", 4); 
                                 @include sv("margin-bottom", 2);
                                 font-size: 2rem;
                                 line-height: 2rem;
                              }
                              @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                                 @include sv("margin-top", 2); 
                              }
                           }
                           p {
                              max-width: 512px;
                              margin: 0 auto;
                              @include sv("padding-bottom", 2.5);
                              // @include sv-tb("margin", 1);
                              // margin-left: 20%;
                              // margin-right: 20%;
                           }
                           button {
                              @include sv-tb("margin", 6);
                              &.start-now {
                                 border: none;
                                 background-color: $green;
                                 color: $white;
                                 @include sv-s("padding", 1, 5.7);
                                 border-radius: 10px;
                                 font-weight: 700;
                                 line-height: 1.5rem;
                                 > span {
                                    @include flexbox;
                                    @include ai(center);
                                    &::after {
                                       @include sv("margin-left", 0.5);
                                       content: "";
                                       background: url(../graphics/arrow-circle-right.svg) no-repeat;
                                       display: inline-block;
                                       width: 30px;
                                       height: 30px;
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
            #workbookQuestionsForm{
               background-color: #FBFBFB;
               .workbookQuestions {
                  //@include sv-s("padding", 4, 10, 4, 8);
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                     @include sv-s("padding", 4, 4, 4, 4);
                     margin: 0 auto 0% 0%;
                  }
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                     @include sv-s("padding", 4, 4, 4, 4);
                  }
               }
            }
       
         }
      }
   }
}


//sweet alert styling
#swal2-title{
   font-size: 1.4em;
}

