// header styles
#header {
   height: auto;
   @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      height: auto;
   }
   .panel-inner {
      .mobile-only-menu {
         display: none;
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include flexbox;
            @include jc(space-between);
            @include ai(center);
            @include sv-tb("padding", 2);
            @include sv-lr("padding", 4);
         }

         .logo {
            background: url(../graphics/election-logo-page.png) no-repeat;
            width: 155px;
            height: 35px;
            // @include sv-s("margin", 2, 0, 0, 2);
         }
         .hamburger-menu {
            @include icon(left, $icon-menu);
            &:before {
               font-size: 2rem;
            }
         }
      }
      #user-details {
         @include flexbox;
         background-color: $green;
         color: $white;
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include flex-direction(column);
         }
         .breadcrumb {
            @include sv-s("padding", 2, 0, 5, 15);
            flex: 1;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
               @include sv-s("padding", 2, 4, 5, 4);
               margin: 0 auto 0 5%;
            }
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               @include sv-s("padding", 2, 4, 5, 4);
               margin-left: 0;
            }
            > div {
               max-width: 1060px;
               margin: 0 auto;
               @include flexbox;
               @include ai(center);
               .icon-chevron-right {
                  @include sv-lr("margin", 0.8);
               }
            }
            .page-position {
               font-weight: 700;
            }
            .menu-title {
               letter-spacing: 0.1em;
               text-transform: uppercase;
               font-size: 0.875rem;
               line-height: 1rem;
               @include sv("margin-top", 4);
            }
         }
         .ribbon {
            flex: 0 0 248px;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               flex: 0 0 100%;
            }
            @include flexbox;
            @include flex-direction(column);
            .percentage {
               background-color: $yellow;
               @include sv-tb("padding", 2.5);
               flex: 70%;
               > span {
                  display: block;
                  text-align: center;
                  &.value {
                     font-size: 2.25rem;
                     line-height: 2.5rem;
                     font-weight: 700;
                  }
                  &.label {
                     font-size: 0.75rem;
                     line-height: 1rem;
                     font-weight: 700;
                     text-transform: uppercase;
                  }
               }
            }
            .print {
               flex: 30%;
               width: 100%;
               background-color: white;
               @include flexbox;
               @include jc(center);
               @include ai(center);
               > a {
                  background: url(../graphics/printer.svg) no-repeat 43%;
                  cursor: pointer;
                  width: 20px;
                  height: 20px;
                  text-decoration: none;
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                     background-position: 45%;
                     height: 40px;
                  }
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                     background-position: 40%;
                  }
                  &:after {
                     content: "Print";
                     @include sv("margin-left", 2.8);
                     font-weight: 700;
                     line-height: 1.5rem;
                     color: $green;
                  }
               }
            }
         }
      }
   }
}
