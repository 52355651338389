// page-design styles
body {
   #wrapper {
      &.noNav {
         #page {
            @include flexbox;
            .sidebar-content {
               display: none;
            }
            .page-content {
               display: none;
            }
         }
      }
      &.withNav {
         // max-width: 1920px;
         #page {
            @include flexbox;
            .sidebar-content {
               flex: 0 0 380px;
               height: 100vh;
               overflow: scroll;
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                  flex: 0 0 33%;
               }
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  flex: 0 0 100%;
               }
            }
            .page-content {
               flex: 1;
               height: 100vh;
               overflow: scroll;
            }
         }
      }
   }
}
