// quiz-form styles

#workbookQuestionsForm {
   .workbookQuestions {
      @include sv-s("padding", 8, 7.2, 8, 15);
      max-width: 1440px;
      margin: 0 auto;
      .items {
         .categorize {
            .answerIt {
               flex: 0 0 40% !important;
               border: 1px solid $mono-grey;
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  flex: 0 0 100% !important;
               }
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                  flex: 0 0 100% !important;
               }
               .targets {
                  border: 1px solid $mono-grey;
                  border-radius: 8px;
                  @include flexbox;

                  > div {
                     width: 50%;
                     text-align: center;
                     background-color: $light-grey !important;
                     @include sv-s("padding", 2.9, 4.8);
                     @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                        @include sv-s("padding", 1.8, 1);
                     }
                     span {
                        background-color: $white;
                        border-radius: 8px;
                        text-align: center;
                        @include sv-s("padding", 1.8, 3);
                        display: inline-block;
                        width: 250px;
                        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                           width: 100%;
                           @include sv-s("padding", 1.8, 1);
                        }
                     }
                  }
               }
               .draggers.target {
                  // @include flexbox;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  float: left;
                  width: 44%;
                  clear: none;
                  border-radius: 10px;
                  padding-top: 10px;
                  padding-bottom: 3px;
                  text-align: center;
                  border: 1px dashed $mono-grey;
                  @include sv("margin-right", 2);
                  @include sv("margin-left", 2);
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                     width: 37%;
                  }

                  .item {
                     @include sv-s("margin", 0, 4.8, 2, 4.8);
                     @include sv-s("padding", 2);
                     text-align: center;
                     border-radius: 8px;
                     background-color: $light-grey;
                     @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                        @include sv-s("margin", 0, 1.2, 2, 1.2);
                        @include sv-s("padding", 1);
                     }
                     &.correct {
                        background-color: $lighter-green;
                     }
                     &.incorrect {
                        background-color: $light-red;
                     }
                     span {
                     }
                  }
                  // width: 50%;
                  // text-align: center;
                  // background-color: $light-grey !important;
                  // @include sv-s("padding", 2.9, 4.8);
               }
               .center.draggers {
                  text-align: center;
                  clear: both;
                  div {
                     cursor: move;
                     @include sv-s("margin", 2);
                     @include sv-s("padding", 2);
                     border: 1px solid $mono-grey;
                     background-color: $light-grey;
                     border-radius: 8px;
                     &.incorrect {
                        background-color: $light-red;
                     }
                     &.correct {
                        background-color: $lighter-green;
                     }
                  }
               }
            }
         }
         .multipleChoice {
            .thisAnswer {
               color: $light-green;
               font-weight: bold;
            }
         }
         .matchup {
            .targets {
               float: right;
               width: 300px;
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  max-width: 100px;
               }
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  max-width: 80px;
               }
               @media screen and (max-width: 1200px) and (min-width: $mobileBreakpoint) {
                  max-width: 200px;
               }
               div {
                  min-width: 300px;
                  min-height: 80px;
                  display: grid;
                  place-items: center;
                  border-radius: 8px;
                  line-height: 24px;
                  @include sv("margin-bottom", 1);
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                     min-width: 100%;
                  }
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                     min-width: 100%;
                  }
                  &:first-child {
                     background-color: $form-matchup-blue !important;
                  }
                  &:nth-child(2) {
                     background-color: $form-matchup-green !important;
                  }
                  &:nth-child(3) {
                     background-color: $form-matchup-mauve !important;
                  }
                  &:nth-child(4) {
                     background-color: $form-matchup-khaki !important;
                  }
                  &:nth-child(5) {
                     background-color: $form-matchup-yellow !important;
                  }
               }
            }
            .draggers {
               width: 300px;
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  width: 150px;
               }
               @media screen and (max-width: 1200px) and (min-width: $mobileBreakpoint) {
                  width: 200px;
               }
               .item {
                  @include icon(left, $icon-menu);
                  min-width: 300px;
                  min-height: 80px;
                  @include flexbox;
                  @include ai(center);
                  @include sv("margin-bottom", 1);
                  border: 1px solid $mono-grey;
                  border-radius: 8px;
                  cursor: move;
                  line-height: 24px;
                  @include sv("padding-right", 1);
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                     min-width: 100%;
                  }
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                     min-width: 100%;
                  }
                  &::before {
                     @include sv("margin-left", 1);
                     @include sv("margin-right", 1.6);
                  }
                  span {
                     width: 100%;
                     @include sv-s("padding", 1.8, 2);
                     border: 1px solid $mono-grey;
                     background-color: $light-grey;
                     border-radius: 8px;
                     @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                        @include sv-s("padding", 1.8, 0.4);
                     }
                     @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                        @include sv-s("padding", 1.8, 0.4);
                     }
                  }
               }
            }
         }
         .correct {
            .scoreLabel {
               @include flexbox;
               font-weight: 700;
               color: $light-green;
               line-height: 1.5rem;
               text-transform: uppercase;
               @include icon(left, $icon-check-circle);
               @include sv("margin-right", 1);
               &::before {
                  @include sv("margin-right", 1);
               }
               // &::before {
               //    content: "";
               //    background: url(../graphics/questions-correct-glyph.gif);
               //    display: inline-block;
               //    width: 16px;
               //    height: 16px;
               //    background-size: contain;
               // }
            }
         }
         .incorrect {
            .scoreLabel {
               @include flexbox;
               font-weight: 700;
               color: $red;
               line-height: 1.5rem;
               text-transform: uppercase;
               @include icon(left, $icon-x-circle);

               &::before {
                  @include sv("margin-right", 1);
               }
            }
            .answerIt {
               .incorrectAnswer {
                  span {
                     color: $red;
                     font-weight: bold;
                  }
               }
               .correctAnswer {
                  color: $light-green;
                  font-weight: bold;
               }
            }
         }
         .draggy {
            .draggable {
               .item {
                  @include icon(left, $icon-menu);
                  min-width: 300px;
                  height: 80px;
                  max-width: 700px;
                  @include flexbox;
                  @include ai(center);
                  @include sv("margin-bottom", 1);
                  border: 1px solid $mono-grey;
                  border-radius: 8px;
                  cursor: move;
                  line-height: 24px;
                  @include sv("padding-right", 1);

                  &::before {
                     @include sv("margin-left", 1);
                     @include sv("margin-right", 1.6);
                  }
                  em {
                     display: none;
                  }
                  span {
                     width: 100%;
                     @include sv-s("padding", 1.8, 2);
                     border: 1px solid $mono-grey;
                     background-color: $light-grey;
                     border-radius: 8px;
                     @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                        @include sv-s("padding", 0.3, 0.2);
                     }
                  }
               }
            }
         }
      }
      .inner {
         h2 {
            font-size: 0;
            &:after {
               font-size: 1rem;
               content: "_________";
            }
         }
         h3 {
            color: $green;
            font-size: 2.25rem;
            line-height: 2.5rem;
            font-weight: 400;
         }
         h4 {
            display: none;
         }
         .field {
            @include flexbox;
            @include row-w;
            @include sv("margin-bottom", 9);
            .intro {
               order: 1;
               flex: 0 0 35%;
               font-size: 1.5rem;
               line-height: 2rem;
               font-weight: 400;
               color: $mono-black;
               @include sv-s("margin", 2, 2, 0, 0);
               a{
                  @media screen and (max-width:#{$mobileBreakpoint - 1}){
                     display: block;
                     width: 250px;
                     word-break: break-all;
                  }
               }
               img{
                  max-width: 400px;
                  max-height: 400px;
                  object-fit: cover;
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                     width: 100%;
                  }
                  @media screen and (max-width:#{$mobileBreakpoint - 1}){
                     width: 100%;
                  }
               }
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  width: 100%;
                  clear: left;
                  min-height: 100%;
                  flex: 0 0 100%;
               }
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                  width: 100%;
                  clear: left;
                  min-height: 100%;
                  flex: 0 0 100%;
                  @include sv-s("margin", 2, 0, 0, 0);
               }
            }
            .explanation {
               order: 2;
               flex: 1;
               line-height: 1.5rem;
               font-weight: 400;
               @include sv-s("padding", 3, 1.7);
               background-color: $light-yellow;
               border-radius: 8px;
               max-height: 700px;
               @include sv("margin-left", 10.5);
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  width: 100%;
                  clear: right;
                  flex: 0 0 100%;
                  @include sv("margin-left", 0);
                  @include sv-tb("margin", 1);
               }
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                  width: 100%;
                  clear: right;
                  flex: 0 0 100%;
                  @include sv("margin-left", 0);
                  @include sv-tb("margin", 1);
               }
            }
            .scoreLabel {
               order: 3;
               flex: 0 0 100%;
            }
            /* Set 3rd div to full width if the explanation class is not present */
            & > :nth-child(3):not(.explanation) {
               //flex: 0 0 100%;
            }

            /* Set 2nd div to full width if the 3rd div is full width */
            div.col:nth-child(2) + div.col:nth-child(3):not(.explanation) {
               flex: 0 0 100%;
            }

            // /* Clear floats to prevent the container from collapsing */
            // div.container:after {
            //    content: "";
            //    display: table;
            //    clear: both;
            // }
            .answerIt {
               order: 4;
               @include sv("margin-top", 8);
               margin-left: 20%;
               margin-right: 20%;
               width: 100%;
               @include ai(center);
               line-height: 1.5rem;
               @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                  margin-left: 0;
                  @include sv("margin-top", 6);
                  @include sv("margin-right", 0);
               }
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                  margin-left: 0;
               }
               .choosable {
                  @include sv("margin-bottom", 2);
                  label {
                     @include flexbox;
                     @include ai(center);
                     max-width: 700px;
                     span.holder {
                        @include sv("margin-right", 1);
                        width: 20px;
                        height: 20px;
                        input[type="checkbox"] {
                           width: 20px;
                           height: 20px;
                        }
                        input[type="radio"] {
                           width: 20px;
                           height: 20px;
                        }
                     }
                     span.description {
                     }
                  }
               }
            }
         }
      }
   }
   .buttonArea-wrapper{
      border-top: 1px solid $mono-grey;
   }
   .buttonArea {
      @include flexbox;
      @include jc(space-between);
      @media screen and (max-width:#{$mobileBreakpoint - 1}){
         justify-content: center;
      }
      input {
         text-transform: uppercase;
         cursor: pointer;
         border: none;
         background-color: $white;
      }
      .prev-area {
         color: rgb(0, 0, 0);
         @include flexbox;
         flex: 0 0 50%;
         @include sv-tb("padding", 5);
         @include sv("padding-left",6);
         @include sv("padding-right", 6);
         border-right: 1px solid $mono-grey;
         @include ai(center);
         @media screen and (min-width:1674px){
            @include sv("padding-left",14.5);
         }
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            padding-left: 1.25em;
            padding-right: 0.85em;
            // @include sv("padding-left", 2.5);
            // @include sv("padding-right", 1);
         }
         .prev-module {
            @include flexbox;
            @include flex-direction(column);
            @include ai(flex-start);
            @include sv("margin-left", 4);
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               @include ai(center);
               @include sv("margin-left", 1);
            }
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
               @include ai(center);
               @include sv("margin-left", 3);
            }
            .prev {
               color: $mono-black;
               line-height: 1.5rem;
               text-transform: uppercase;
            }
            .prevTitle {
               font-weight: 700;
               font-size: 1.25rem;
               color: #000;
               text-align: left;
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                  text-align: center;
               }
               @media screen and (max-width:#{$mobileBreakpoint - 1}){
                  text-align: center;
                  font-size: 1rem;
               }
            }
         }
         .image-prev{
            &:not(.noPrevArrow){     
               @include icon(left, $icon-arrow-circle-left);
               &::before {
                  font-size: 2.5rem;
                  @include sv("margin-left", 1);
                  color: #000;
               }
            }
          
         }
         button {
            @include flexbox;
            @include ai(center);
            border: none;
            background: none;
            cursor: pointer;
            
         }
  
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            
            @include jc(center);
         }
      }
      .next-area {
         @include flexbox;
         @include jc(flex-end);
         flex: 0 0 45%;
         @include sv-tb("padding", 5);
         @include sv("padding-right", 6.2);
         text-align: right;
         color: rgb(0, 0, 0);
         @include ai(center);
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            padding-right: 1.25em;
            padding-left: 0.85em;
            
            // @include sv("padding-right", 2.5);
            // @include sv("padding-left", 1);
         }
         .next-module {
            @include flexbox;
            @include flex-direction(column);
            @include ai(flex-end);
            @include sv("margin-right", 4);
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               @include sv("margin-right", 1);
               @include ai(center);
            }
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
               @include ai(center);
               @include sv("margin-right", 3);
            }
            .next {
               color: $mono-black;
               line-height: 1.5rem;
               text-transform: uppercase;
            }
            .nextTitle {
               font-weight: 700;
               font-size: 1.25rem;
               color: #000;
               text-align: right;
               @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
                  text-align: center;
                  font-size: 1rem;
               }
               @media screen and (max-width:#{$mobileBreakpoint - 1}){
                  text-align: center;
               }
            }
            
         }
         .image-next{
            @include icon(right, $icon-arrow-circle-right);
            &::after {
               font-size: 2.5rem;
               @include sv("margin-right", 1);
               color: #000;
            }
         }
         button {
            @include flexbox;
            @include ai(center);
            border: none;
            background: none;
            cursor: pointer;
            
         }

         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            
            @include jc(center);
         }
      }
   }
}
