@mixin breakpoint($class, $offset:0) {
    @if $class == xsmall {
        @media (max-width: 376px + $offset) { @content; }
    }

    @if $class == small {
        @media (max-width: 640px + $offset) { @content; }
    }

    @if $class == medium {
        @media (max-width: 768px + $offset) { @content; }
    }

    @if $class == large {
        @media (max-width: 1024px + $offset) { @content; }
    }

    @if $class == xlarge {
        @media (max-width: 1440px + $offset) { @content; }
    }

    @if $class == custom {
        @media (max-width: 0px + $offset) { @content; }
    }


    @else {
        /*@warn  $offset "  Breakpoint not defined";*/
    }
}


@mixin breakpoint-min($class, $offset:0) {
    @if $class == xsmall {
        @media (min-width: 377px + $offset) { @content; }
    }

    @if $class == small {
        @media (min-width: 641px + $offset) { @content; }
    }

    @if $class == medium {
        @media (min-width: 769px + $offset) { @content; }
    }

    @if $class == large {
        @media (min-width: 1025px + $offset) { @content; }
    }

    @if $class == xlarge {
        @media (min-width: 1441px + $offset) { @content; }
    }

    @if $class == custom {
        @media (min-width: 1px + $offset) { @content; }
    }


    @else {
        /*@warn  $offset "  Breakpoint not defined";*/
    }
}