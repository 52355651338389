// Example: @include button(no_chevron_red);
@mixin button($size:sm, $rounded: none, $state: 'normal') {
  border: 1px solid transparent;

  @if $size == sm {
    @include sv-s(padding, 0.8, 1.6);
    @include fontstyle(text-base);
    font-weight: 500;
  } @else if $size == md {
    @include fontstyle(text-base);
    @include sv-s(padding, 1.8, 2.6);
    font-weight: 700;
  } @else if $size == lg {
    @include fontstyle(text-xl);
    @include sv-s(padding, 1.6, 4);
    font-weight: 700;
  } @else {
    @include sv-s(padding, 0.8, 1.6);
    @include fontstyle(text-base);
    font-weight: 500;
  }
  font-family: 'Space Mono';
  font-display: block;
  @if $rounded == 'full' {
    border-radius: 999px;
  } @else if $rounded == 'semi' {
    border-radius: 4px;
  }


  @if $state == 'disabled' {
    background: #71717A;
    opacity: 0.5;
  } @else {
    background-color: #FFF;
    color: #000;
    border-color: #000;
  }

  @content;
}

@mixin link($color: $blue) {
  text-decoration: none;
  color: $color;
  border-bottom: 2px solid $color;
  font-weight: 500;
  // @include sv(padding-bottom, 0.5);
  @include sv-lr(padding, 0.2);
  &:hover {
    color: darken($color, 10%);
  }
}