// #Normalise
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	background-color: white;
	font-size: #{$browser-context}px;
	font-family: $defaultFont;
	font-weight: $defaultFontWeight;
	@media screen and(max-width: 2560px) {
		font-size: 16px;
	}
	font-style: normal;
	font-display: block;
	line-height: #{$line-height / $browser-context};
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	max-width: 100vw;
	overflow-x: hidden;
}
//targeting safari only browser for overflowing login screen with width 100vw
@media screen and (-webkit-min-device-pixel-ratio: 0) {	 _::-webkit-full-page-media, _:future, :root , html,body { 
	position: relative;
	overflow-x: hidden;
 }		 }


	
html,
body {
	width: 100vw;
	text-rendering: optimizeLegibility;
}

h1 {
	@include fontstyle(text-5xl, $color: "default");
	font-weight: 400;
}

h2 {
	@include fontstyle(text-4xl, $color: "default");
	font-weight: 400;
}

h3 {
	@include fontstyle(text-2xl, $color: "default");
	font-weight: 400;
}

h4 {
	@include fontstyle(text-2xl, $color: "default");
	font-weight: 400;
}

h5 {
	@include fontstyle(text-xl, $color: "default");
	font-weight: 400;
}

h6 {
	@include fontstyle(text-lg, $color: "default");
	font-weight: 400;
}

.layout-content {
	form {
		@include form;
	}
}

.no-scrolling {
	overflow: hidden;
}

.display-none {
	@include display-none();
}

.appearance-none {
	@include appearance-none();
}

.display-mob,
.mobile-only {
	display: none;
}

.row_nw {
	@include row_nw();
}

.row_w {
	@include row_w();
}

.col_nw {
	@include col_nw();
}

.col_w {
	@include col_nw();
}

.flex_c {
	@include flex_c();
}

.jc-sb {
	@include jc(space-between);
}

.jc-sa {
	@include jc(space-around);
}

.jc-se {
	@include jc(space-evenly);
}

.jc-c {
	@include jc(center);
}

.jc-fs {
	@include jc(flex-start);
}

.jc-fe {
	@include jc(flex-end);
}

.ai-c {
	@include ai(center);
}

.ai-fs {
	@include ai(flex-start);
}

.ai-fe {
	@include ai(flex-end);
}

@include breakpoint(medium) {
	.display-mob {
		display: block;
	}

	.mobile-only {
		display: inline-block;
	}

	.desktop-only {
		display: none;
	}
}

pre {
	color: rgba(90, 19, 19, 1);
}

.visually-hidden {
	display: none;
}

.block-system-main-block {
	> form {
		@include block-margin;
	}
}

// .page-wrapper {
//     height: 100vh;
//     overflow: hidden;
// }

.views-exposed-form.views-exposed-form {
	box-shadow: none;
}
.accessible {
	display: none;
}
